import { useRef, useState } from 'react';

const STATE_ACCEPTING = 0;
const STATE_SENDING   = 1;
const STATE_FAILED    = 2;
const STATE_SENT      = 3;

function App() {
  const form = useRef();
  const [state, setState] = useState(STATE_ACCEPTING);
  const [failReason, setFailReason] = useState(null);

  const formSubmit = e => {
    e.preventDefault();

    setState(STATE_SENDING);

    const data = {
      'at': new Date().toISOString(),
      'purpose': form.current.purpose.value,
      'safety': +form.current.safety.value,
      'comfort': +form.current.comfort.value,
      'entertainment': +form.current.entertainment.value,
      'comments': form.current.comments.value
    };
    
    fetch('https://api.hpkns.uk/rate-my-driving', {
      method: 'POST',
      headers: {
        'Authorization': 'Basic cmF0ZS1teS1kcml2aW5nLXN1Ym1pc3Npb246djd4MmozN2ZoQ0tS',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(r => r.json())
    .then(r => {
      if (r.ok) {
        setState(STATE_SENT);
      } else {
        setState(STATE_FAILED);
        setFailReason(r.reason)
      }
    }).catch(r => {
      setState(STATE_FAILED);
      setFailReason(r);
    });
  };

  if (state === STATE_SENT) {
    return (
      <div className='u-fixed-width container'>
        <div className='p-notification--positive'>
          <div className='p-notification__content'>
            <h5 className='p-notification__title'>Thank you!</h5>
            <p className='p-notification__message'>Your feedback has been accepted!</p>
          </div>
        </div>
      </div>
    );
  } else if (state === STATE_SENDING) {
    return (
      <div className='u-fixed-width container'>
        <h1>
          <i className='p-icon--spinner u-animation--spin' style={{ marginBottom: '0.05em', marginRight: '0.5em' }}></i>
          Sending your review...
        </h1>
      </div>
    );
  }
  
  return (
      <div className='u-fixed-width container'>
        {state === STATE_FAILED ? <div class='p-notification--negative'>
          <div className='p-notification__content'>
            <h5 className='p-notification__title'>Failed to send feedback</h5>
            <p className='p-notification__message'>{failReason}</p>
          </div>
        </div> : undefined}

        <form onSubmit={formSubmit} ref={form}>
          <fieldset>
            <legend className='is-required'>What was the purpose of this journey?</legend>

            <label className='p-radio'>
              <input type='radio' className='p-radio__input' name='purpose' value={'Personal'} />
              <span className='p-radio__label'>Personal</span>
            </label>
            <label className='p-radio'>
              <input type='radio' className='p-radio__input' name='purpose' value={'Commuting'} />
              <span className='p-radio__label'>Commuting</span>
            </label>
            <label className='p-radio'>
              <input type='radio' className='p-radio__input' name='purpose' value={'Work'} />
              <span className='p-radio__label'>Work</span>
            </label>
          </fieldset>

          <fieldset>
            <legend className='is-required'>Ratings</legend>

            <div className='u-sv3'>
              <p>How safe did this journey feel, where 1 is unsafe and 5 is very safe?</p>
              {[1, 2, 3, 4, 5].map(e => (
                <label className='p-radio--inline' key={e} style={{marginRight: '4em'}}>
                  <input type='radio' className='p-radio__input' name='safety' value={e} defaultChecked={e === 3} />
                  <span className='p-radio__label'>{e}</span>
                </label>
              ))}
            </div>

            <div className='u-sv3'>
              <p>How comfortable did this journey feel, where 1 is uncomfortable and 5 is very comfortable?</p>
              {[1, 2, 3, 4, 5].map(e => (
                <label className='p-radio--inline' key={e} style={{marginRight: '4em'}}>
                  <input type='radio' className='p-radio__input' name='comfort' value={e} defaultChecked={e === 3} />
                  <span className='p-radio__label'>{e}</span>
                </label>
              ))}
            </div>

            <div className='u-sv3'>
              <p>How good was the in-car entertainment, where 1 is really bad and 5 is really good?</p>
              {[1, 2, 3, 4, 5].map(e => (
                <label className='p-radio--inline' key={e} style={{marginRight: '4em'}}>
                  <input type='radio' className='p-radio__input' name='entertainment' value={e} defaultChecked={e === 3} />
                  <span className='p-radio__label'>{e}</span>
                </label>
              ))}
            </div>
          </fieldset>

          <p className='label'>Any further comments&hellip;</p>
          <textarea name='comments'></textarea>

          <button className='p-button--positive'>Submit</button>
        </form>
      </div>
    );
  }
  
  export default App;
  